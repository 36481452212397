// ShippingPolicy.js
import React from "react";
import "./ShippingPolicy.css";

const ShippingPolicy = () => {
  return (
    <div className="shipping-policy-container">
      <h2 className="title">Shipping Policy</h2>

      <section className="section">
        <h3 className="section-title">Is Shipping Free?</h3>
        <p>Yes, shipping is FREE all over India.</p>
      </section>

      <section className="section">
        <h3 className="section-title">When Will I Receive My Order?</h3>
        <p>
          Orders are shipped out directly from any of our warehouses, and they
          will do everything they can to get you your order as fast as they can!
          Due to the popularity of our offers, please allow an estimated 3-8 days
          for your order to arrive.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Can I Track My Order?</h3>
        <p>
          YES, you will receive an AWB number (within 1-2 days) once you place an
          order. You can track your order on our Tracking Page with the help of
          the AWB number. If you still face issues tracking your order, feel free
          to call or WhatsApp us at{" "}
          <a href="tel:+918005629558">+91 8005629558</a>.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Why Is My Order Being Shipped in Different Packages?</h3>
        <p>
          If you have a multi-item order, each item may be shipped from a
          different warehouse, depending on which one has them available the
          fastest. Alternatively, if an item is popular and on a bit of a back
          order, we might ship your items at different times, in different
          packages, to prevent holding up your order and to get it to you as fast
          as possible!
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">What Happens If My Order Gets Stuck or Lost in the Mail?</h3>
        <p>
          All of our orders are sent with insured shipping and handling. If an
          order gets stuck somewhere, sent back, or even lost during the delivery
          process, we apologize! The postal service is out of our control. However,
          in cases like this, because the packages are insured, we will send you a
          new package with quicker shipping and full tracking, if possible. Please
          see our refund and return policy for when these might be applicable to
          shipping situations.
        </p>
      </section>
    </div>
  );
};

export default ShippingPolicy;
