import React from 'react';
import './Overlay.css';

const Overlay = ({open}) => {
  return (
    <div className={`overlay ${open ? 'visible' : 'hidden'} `}>
    </div>
  )
}

export default Overlay;
