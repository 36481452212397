// import React from "react";
// import { Link } from "react-router-dom";
// import blogs from "./blogsData";
// import "./CSS/BlogPage.css";

// const BlogPage = () => {
//   return (
//     <div className="blog-container">
//       {blogs.map((blog) => (
//         <div key={blog.id} className="blog-card">
//           <div className="blog-image-wrapper">
//             <img src={blog.image} alt={blog.title} className="blog-image" />
//           </div>
//           <div className="blog-content">
//             <h2 className="blog-title">{blog.title}</h2>

//             {/* <p className="blog-description">{blog.description}</p> */}
//             <div className="link-box">
//               <Link to={`/blog/${blog.id}`} className="blog-read-more">
//                 Read More...
//               </Link>

//               <a
//                 href={blog.product_link}
//                 className="blog-product-link"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 Buy
//               </a>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default BlogPage;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./CSS/BlogPage.css";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://jaipurmasale-backend.onrender.com/api/get-all-blogs/");
        const data = await response.json();
        setBlogs(data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  // Generate JSON-LD structured data for all blog posts
  const blogSchemaData = blogs.map((blog) => ({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": blog.title,
    "image": blog.image,
    "author": {
      "@type": "Person",
      "name": "Jaipur Masale"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Jaipur Masale",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.jaipurmasale.com/logo.webp" // Adjust to your logo URL
      }
    },
    "datePublished": blog.date, // Assuming `blog.date` holds publish date
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://www.jaipurmasale.com/blog/${blog.id}`
    },
    "description": blog.description || "Discover the latest updates and recipes from Jaipur Masale"
  }));

  return (
    <div className="blog-container">
      <Helmet>
        <link rel="canonical" href="https://www.jaipurmasale.com/Blog" />
        {/* Add JSON-LD script with structured data for each blog */}
        <script type="application/ld+json">
          {JSON.stringify(blogSchemaData)}
        </script>
      </Helmet>
      {blogs.map((blog) => (
        <div key={blog.id} className="blog-card">
          <div className="blog-image-wrapper">
            <img src={blog.image} alt={blog.title} className="blog-image" />
          </div>
          <div className="blog-content">
            <h2 className="blog-title">{blog.title}</h2>
            <div className="link-box">
              <Link to={`/blog/${blog.id}`} className="blog-read-more">
                Read More...
              </Link>
              <a
                href={`${blog.product_link}`}
                className="blog-product-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPage;
