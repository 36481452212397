import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="title">Privacy Policy</h1>
      <p className="intro-text">
        At KRAAFTY, we prioritize your privacy and are committed to safeguarding your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to protect it when you visit our website, www.kraafty.com, and purchase our products.
      </p>

      <div className="section">
        <h2>1. Information We Collect</h2>
        <ul>
          <li>
            <strong>Personal Data:</strong> We collect personally identifiable information such as your name, shipping address, email address, and phone number when you make a purchase or contact us. This information is essential for processing your orders and ensuring they reach you accurately.
          </li>
          <li>
            <strong>Payment Information:</strong> To process your orders, we collect payment details. This information is securely handled by our payment gateway providers to ensure your financial data is protected.
          </li>
          <li>
            <strong>Usage Data:</strong> We gather information about your interactions with our website, including your IP address, browser type, and the pages you visit. This data helps us understand how you use our site and improve your experience.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>2. How We Use Your Information</h2>
        <ul>
          <li>
            <strong>Order Processing:</strong> The primary use of your personal data is to process and fulfill your orders. This includes verifying your payment information, preparing your items for shipment, and delivering them to your specified address.
          </li>
          <li>
            <strong>Communication:</strong> We use your contact information to communicate with you about your orders, respond to your inquiries, and send you promotional offers. This ensures you are kept informed about your purchases and any special deals.
          </li>
          <li>
            <strong>Website Improvement:</strong> By analyzing usage data, we can identify areas of our website that need improvement. This helps us enhance your browsing experience and make our services more user-friendly.
          </li>
          <li>
            <strong>Legal Compliance:</strong> We may use your information to comply with legal obligations, such as tax regulations and fraud prevention, and to protect our rights and interests.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>3. Sharing Your Information</h2>
        <ul>
          <li>
            <strong>No Selling or Renting:</strong> We do not sell or rent your personal information to third parties. Your data is used solely for the purposes outlined in this policy.
          </li>
          <li>
            <strong>Service Providers:</strong> We may share your information with trusted service providers who assist us in operating our website, processing payments, and delivering orders. These providers are contractually obligated to protect your information and use it only for the services they provide to us.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights and safety. This includes responding to legal requests and preventing harm or fraud.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>4. Security of Your Information</h2>
        <ul>
          <li>
            <strong>Security Measures:</strong> We implement administrative, technical, and physical security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. These measures include encryption, secure servers, and access controls.
          </li>
          <li>
            <strong>Limitations:</strong> While we strive to protect your information, no method of transmission over the internet or electronic storage is completely secure. We cannot guarantee absolute security, but we continuously work to enhance our security practices.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>5. Your Rights</h2>
        <ul>
          <li>
            <strong>Access and Correction:</strong> You have the right to access, correct, or delete your personal information. This ensures that the data we hold about you is accurate and up to date.
          </li>
          <li>
            <strong>Opt-Out:</strong> You can opt out of receiving promotional communications from us at any time. This allows you to control the marketing messages you receive and ensures your preferences are respected.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>6. Changes to This Privacy Policy</h2>
        <ul>
          <li>
            <strong>Updates:</strong> We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any changes will be posted on this page with an updated revision date, so you are always aware of the current policy.
          </li>
          <li>
            <strong>Review:</strong> We encourage you to review this policy periodically to stay informed about how we are protecting your information.
          </li>
        </ul>
      </div>

      <div className="section">
        <h2>7. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:jaipurmasale@gmail.com">jaipurmasale@gmail.com</a>. We are here to address any issues and ensure your privacy is respected.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
