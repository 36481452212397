import React from 'react';
import jaipur_masale from '../Assets/jaipur_masale.webp';
import './Footer2.css';
import background from '../Assets/rajasthan4.webp';
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  XOutlined,
  YoutubeOutlined,
  WhatsAppOutlined,
  AmazonOutlined,
} from '@ant-design/icons';

const Footer2 = () => {
  return (
    <footer
      className="relative text-white bg-cover bg-center py-10 md:py-16 overflow-hidden"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${background})`,
      }}
    >
      <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 footer-columns">
        {/* Brand Details */}
        <div className="flex flex-col items-center md:items-start">
          <img src={jaipur_masale} alt="Brand Logo" className="w-40 md:w-48" />
          <p className="text-center md:text-left text-sm">
            Experience Jaipur Masale & Dry Fruits, where tradition and innovation unite in every
            Flavourful experience. A brand that respects heritage while delivering exquisite tastes
            to refined palates.
          </p>
        </div>

        {/* Policy Links */}
        <div className="footer-policy">
          <h3 className="text-lg font-semibold mb-4">POLICY</h3>
          <ul className="space-y-2 text-sm list-none">
            <li>
              <a href="/privacy" className="text-white no-underline" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/refund" className="text-white no-underline" target="_blank" rel="noopener noreferrer">
                Refund Policy
              </a>
            </li>
            <li>
              <a href="/shipping" className="text-white no-underline" target="_blank" rel="noopener noreferrer">
                Shipping Policy
              </a>
            </li>
            <li>
              <a href="/terms-of-service" className="text-white no-underline" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </li>
          </ul>
        </div>

        {/* Main Menu Links */}
        <div className="footer-menu">
          <h3 className="text-lg font-semibold mb-4">MAIN MENU</h3>
          <ul className="space-y-2 text-sm list-none">
            <li>
              <a href="/" className="text-white no-underline">
                Home
              </a>
            </li>
            <li>
              <a href="/collections" className="text-white no-underline">
                Collections
              </a>
            </li>
            <li>
              <a href="/best-sellers" className="text-white no-underline">
                Best Sellers
              </a>
            </li>
            <li>
              <a href="/our-story" className="text-white no-underline">
                Our Story
              </a>
            </li>
            <li>
              <a href="/about-us" className="text-white no-underline">
                About Us
              </a>
            </li>
          </ul>
        </div>

        {/* Showroom Contact Details */}
        <div className="footer-contact">
          <h3 className="text-lg font-semibold mb-4">Factory</h3>
          <ul className="space-y-2 text-sm list-none">
            <li className="text-white">Monday – Sunday: 9:00 am – 9:00 pm</li>
            <li className="text-white">C-8 Raghunath Colony, Galta Gate, Jaipur, Rajasthan, 302002</li>
            <li className="text-white">📞 +91 8764576256</li>
            <li className="text-white">✉️ jaipurmasale@gmail.com</li>
            <li className="text-white">🌐 jaipurmasale.com</li>
          </ul>
        </div>
      </div>

      {/* Divider Line */}
      <div className="w-full h-px bg-white my-6"></div>

      {/* Social Media Icons */}
      <div className="footer-social-icons">
        {[InstagramOutlined, YoutubeOutlined, LinkedinOutlined, FacebookOutlined, WhatsAppOutlined, XOutlined, AmazonOutlined].map((Icon, index) => (
          <div key={index} className="footer-icons-containers">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Icon style={{ fontSize: 25 }} />
            </a>
          </div>
        ))}
      </div>

      {/* Copyright Section */}
      <div className="text-center text-sm text-white mt-3">Copyright @ 2024 - All Right Reserved.</div>
    </footer>
  );
};

export default Footer2;
