import React, { useRef, useContext, useState, useEffect} from "react";
import "./CartItems.css";
import { ShopContext } from "../../Context/ShopContext";
import remove_icon from "../Assets/cart_cross_icon.webp";
import axios from "axios";
import useRazorpay from "react-razorpay";
import { FaCreditCard, FaPaypal } from 'react-icons/fa'; // Importing icons
import { useNavigate } from "react-router-dom";

const CartItems = () => {
  const { getTotalCartAmount, cartItems, removeFromCart, fetchCartItems } =
    useContext(ShopContext);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [Razorpay] = useRazorpay();
  const [errorNotification, setErrorNotification] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(""); // State to track payment method
  const [showPaypalButton, setShowPaypalButton] = useState(false);
  const [isCashOnDelivery, setIsCashOnDelivery] = useState(false); // State for COD option
  const [shippingFee, setShippingFee] = useState(0); // State for shipping fee

  useEffect(() => {
    // Update shipping fee when COD is selected/deselected
    setShippingFee(isCashOnDelivery ? 39 : 0);
  }, [isCashOnDelivery]);


  // New state for form visibility and form data
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    delivery_address: "",
    name: "",
    country_code: "",
    mobile_number: "",
    pin_code: "",
    city: "",
    state: "",
    comment: "",
  });

  const [paypalError, setPaypalError] = useState(""); // State for PayPal errors

  const formRef = useRef(null);
  const handleContinueClick = () => {
    // First, show the form
    setShowForm(true);

    // Then scroll to the form after it's visible
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Add a small delay to ensure the form renders before scrolling
  };

  // Promo Code Handler
  const handlePromoCodeSubmit = async () => {
    const totalAmount = getTotalCartAmount();
    const token = localStorage.getItem("token");

    // Calculate total number of items in the cart
    const numberOfItems = cartItems.reduce((total, item) => total + item.quantity, 0);

    try {
      const response = await axios.post(
        "https://jaipurmasale-backend.onrender.com/api/validate-promo-code/",
        {
          amount: totalAmount,
          promo_code: promoCode,
          no_of_products: numberOfItems, // <-- Include number_of_items in the request
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("response");

      if (response?.data?.amount !== undefined && response.data.message) {
        setDiscount(response.data.amount);
        setMessage(response.data.message);
        setMessageType("success");
      } else {
        setMessage("Promo code not applied");
        setMessageType("error");
        setDiscount(0);
      }
    } catch (error) {
      setMessage("Promo code not applied");
      setMessageType("error");
      setDiscount(0);
    }
  };

  const [showNotification] = useState(false);

  const navigate = useNavigate();

  // Async function to verify payment
  const verify_payment = async (paymentDetails, paymentType) => {
    const totalAmount = getTotalCartAmount() - discount;
    const token = localStorage.getItem("token");
    console.log("paymentType", paymentType);
    try {
      const payload = {
        amount: totalAmount * 100,
        razorpay: paymentType === "razorpay",
      };

      console.log("paymentType", paymentType);
      console.log("Payload before request:", payload);

      if (paymentType === "razorpay") {
        payload.razorpay_payment_id = paymentDetails.payment_id;
        payload.razorpay_order_id = paymentDetails.order_id;
        payload.razorpay_signature = paymentDetails.signature;
      } else if (paymentType === "paypal") {
        payload.paypal_order_id = paymentDetails.order_id;
      }
      console.log("Payload before request:", payload);

      const response = await axios.post(
        "https://jaipurmasale-backend.onrender.com/api/verify-payment/",
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.data) {
        fetchCartItems();
        navigate("/");
      }
    } catch (error) {
      console.error("Payment verification failed:", error);
    }
  };


  const handleRazorpayPayment = async (order_id) => {
    // const totalAmount = getTotalCartAmount() - discount;
    try {
      const options = {
        // key: "rzp_test_CGyfBEXFXeiBsi", // Razorpay test key
        key: "rzp_live_liYPHRU3JwvCSw",
        name: "Jaipur Masale & Dry Fruits",
        description: "Jaipur Masale & Dry Fruits Secure Transaction",
        image: "https://www.jaipurmasale.com/logo.webp",
        order_id: order_id,
        handler: function (response) {
          verify_payment(
            {
                payment_id: response.razorpay_payment_id,
                order_id: response.razorpay_order_id,
                signature: response.razorpay_signature
            },
            "razorpay"
          );
        },
        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        console.error("Payment failed:", response.error);
      });

      rzp1.open();
    } catch (error) {
      console.error("Error during Razorpay checkout:", error);
    }
  };

  const handlePaypalPayment = async () => {
    const totalAmount = getTotalCartAmount() - discount;
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://jaipurmasale-backend.onrender.com/api/create-order/",
        {
          amount: totalAmount,
          currency: "USD",
          promo_code: promoCode || "Nil",
          razorpay: false,
          ...formData,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const order_id = response?.data?.id;
      if (!order_id) {
        setPaypalError("Order creation failed, please try again.");
        return;
      }
      setShowPaypalButton(true);

      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: totalAmount.toFixed(2),
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const details = await actions.order.capture();
            await verify_payment({ order_id: details.id }, "paypal");
          },
          onError: (err) => {
            setPaypalError("Payment failed, please try again.");
          },
        })
        .render("#paypal-button-container");
    } catch (error) {
      setPaypalError("Error creating order, please try again.");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const totalAmount = getTotalCartAmount() - discount;

    const countryCodeRegex = /^[0-9]{1,4}$/;
    const pinCodeRegex = /^[0-9]{6}$/;

    const showErrorNotification = (message) => {
      setErrorNotification(message);
      setTimeout(() => {
        setErrorNotification("");
      }, 5000);
    };

    if (!formData.name || !formData.mobile_number || !formData.delivery_address) {
    showErrorNotification("Please fill in all required fields.");
    return;
  }

  if (!countryCodeRegex.test(formData.country_code)) {
    showErrorNotification("Invalid country code. Please enter 1 to 4 digits.");
    return;
  }

  if (!pinCodeRegex.test(formData.pin_code)) {
    showErrorNotification("Invalid pin code. Please enter a 6-digit number.");
    return;
  }

    try {
      const token = localStorage.getItem("token");
      const res = await axios.post(
        "https://jaipurmasale-backend.onrender.com/api/create-order/",
        {
          amount: totalAmount * 100,
          currency: "INR",
          promo_code: promoCode || "Nil",
          cash_on_delivery: isCashOnDelivery, // <-- Send COD status (true/false)
          ...formData,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const order_id = res.data.data.id;

      if (paymentMethod === "razorpay") {
        handleRazorpayPayment(order_id);
      } else if (paymentMethod === "paypal") {
        handlePaypalPayment();
      }
    } catch (error) {
      setErrorNotification("An unexpected error occurred. Please try again.");
      setTimeout(() => {
        setErrorNotification("");
      }, 5000);
    }
  };

  const finalAmount = getTotalCartAmount() - discount + shippingFee;

  const sizeMap = {
    S: "100 gm",
    M: "250 gm",
    L: "500 gm",
    XL: "1 Kg",
  };

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Size</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {cartItems.length > 0 ? (
        cartItems.map((item) => (
          <div key={item.id}>
            <div className="cartitems-format cartitems-format-main">
              <img src={item.image} alt={item.product_name} className="carticon-product-icon" loading="lazy" />
              <p>
                {(() => {
                const words = item.product_name.split(' ');
                return words.length > 10
                ? `${words.slice(0, 10).join(' ')}...`
                : item.product_name;
                })()}
              </p>
              <p>Rs {item.offer_price}</p>
              {/* Convert the size using sizeMap */}
              <p>{sizeMap[item.size] || item.size}</p>
              <button className="cartitems-quantity">{item.quantity}</button>
              <p>Rs {item.offer_price * item.quantity}</p>
              <img
                className="cartitems-remove-icon"
                src={remove_icon}
                onClick={() =>
                  removeFromCart(item.id, item.size, item.offer_price, item.mrp)
                }
                alt="Remove"
              />
            </div>
            <hr />
          </div>
        ))
      ) : (
        <p>No items in the cart.</p>
      )}
      <div className="cartitems-down">
        <div className="cartitems-promocode">
          <p>If you have a promo code, enter it here</p>
          <div className="cartitems-promobox">
            <input
              type="text"
              placeholder="Promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button onClick={handlePromoCodeSubmit}>Submit</button>
          </div>
          {message && (
            <p
              className={`cartitems-message ${
                messageType === "success" ? "success" : "error"
              }`}
            >
              {message}
            </p>
          )}
        </div>

        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>Rs {getTotalCartAmount()}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>Rs {shippingFee === 0 ? "Free" : shippingFee}</p>
            </div>
            <hr />
            {discount > 0 && (
              <div className="cartitems-total-item">
                <p>Discount</p>
                <p>Rs {discount}</p>
              </div>
            )}
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>Rs {finalAmount}</h3>
            </div>
          </div>
          {/* Cash on Delivery Checkbox */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="cod"
              checked={isCashOnDelivery}
              onChange={() => setIsCashOnDelivery(!isCashOnDelivery)}
              className="h-5 w-5"
            />
            <label htmlFor="cod" className="text-gray-600">Cash on Delivery</label>
          </div>
          <button onClick={handleContinueClick}>CLICK TO CONTINUE</button>
        </div>
      </div>

      {showForm && (
        <div
          ref={formRef}
          className="checkout-form w-full max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg"
        >
          <h2>Fill the Delivery Information</h2>
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Delivery Address"
              value={formData.delivery_address}
              onChange={(e) =>
                setFormData({ ...formData, delivery_address: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Country Code"
              value={formData.country_code}
              onChange={(e) =>
                setFormData({ ...formData, country_code: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Mobile Number"
              value={formData.mobile_number}
              onChange={(e) =>
                setFormData({ ...formData, mobile_number: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Pin Code"
              value={formData.pin_code}
              onChange={(e) =>
                setFormData({ ...formData, pin_code: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="City"
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="State"
              value={formData.state}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              required
            />
            <textarea
              placeholder="Comments (optional)"
              value={formData.comment}
              onChange={(e) =>
                setFormData({ ...formData, comment: e.target.value })
              }
            />
            <div className="payment-buttons mx-2">
            {!isCashOnDelivery && (
            <div className="flex justify-around mt-4">
              <div
                className="cursor-pointer text-center p-4 border rounded-lg"
                onClick={() => setPaymentMethod("razorpay")}
                style={{ borderColor: paymentMethod === 'razorpay' ? 'blue' : 'gray' }}
              >
                <FaCreditCard size={50} color={paymentMethod === 'razorpay' ? 'blue' : 'gray'} />
                <p className="mt-2">Pay with Razorpay</p>
              </div>

              <div
                className="cursor-pointer text-center p-4 border rounded-lg"
                onClick={() => { setPaymentMethod("paypal"); setShowPaypalButton(true); }}
                style={{ borderColor: paymentMethod === 'paypal' ? 'blue' : 'gray' }}
              >
                <FaPaypal size={50} color={paymentMethod === 'paypal' ? 'blue' : 'gray'} />
                <p className="mt-2">Pay with PayPal</p>
              </div>
            </div>
            )}
        <button className="mt-4" type="submit">Submit</button>
            </div>
          </form>
        </div>
      )}
      {showNotification && (
        <div className="fixed top-24 right-8 bg-green-500 text-white p-4 rounded-lg shadow-lg">
          Your Order Placed successfully, You get invoice over Mail/Whatsapp.
        </div>
      )}
      {errorNotification && (
        <div className="fixed top-28 left-4 w-full p-4 bg-red-500 text-white text-center">
          <p>{errorNotification}</p>
        </div>
      )}
      {paypalError && (
        <div className="fixed top-28 left-4 w-full p-4 bg-red-500 text-white text-center">
          <p>{paypalError}</p>
        </div>
      )}

      {paymentMethod === "paypal" && showPaypalButton && (
        <div id="paypal-button-container"></div>
      )}

    </div>
  );
};

export default CartItems;

