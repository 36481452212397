import React from "react";
import "./RefundPolicy.css";

const RefundPolicy = () => {
  return (
    <div className="refund-policy-container">
      <h2 className="title">Refund Policy</h2>

      <section className="section">
        <h3 className="section-title">Return Authorization</h3>
        <p>
          In order to process a return or an exchange, please contact us for a
          return authorization. We accept returns and exchanges within 3 DAYS of
          delivery.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Returns & Exchanges</h3>
        <p>
          We offer easy returns & exchanges on all orders. To return an item,
          please contact us and we will generate a return authorization for you.
          Returns are only accepted for items purchased directly from our website.
          The item(s) must be unused and in original packaging. To complete an
          exchange, place a new order for the item you would like. Please contact
          us with your order number and we will create a return authorization for
          your previous item.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Refunds</h3>
        <p>
          Once we receive the return order, it will take 3-5 business days for
          an exchange. If 3-5 business days have passed and you still don’t see
          an exchange, please feel free to contact us at{" "}
          <a href="mailto:jaipurmasale@gmail.com">jaipurmasale@gmail.com</a>.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Invalid Reasons for Refunds</h3>
        <p>
          - Buyer no longer wants the items: This is the most common example
          which we cannot honor if we want to continue offering great value at
          KRAAFTY. The buyer should make sure they want to buy the items before
          submitting an order, not after. Once an order is submitted, the buyer
          enters into a legally binding contract with the seller to purchase all
          items in that order.
        </p>
        <p>
          - Buyer should be confident that they are ready to pay the asking
          prices before submitting an order. After the order is submitted, the
          buyer enters into a legally binding contract with the seller to purchase
          all items in that order.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Cancellations</h3>
        <p>
          In the event you wish to cancel your order, please contact us. If the
          order has already shipped, we would be unable to stop the package.
        </p>
      </section>
    </div>
  );
};

export default RefundPolicy;
