import React from 'react';
import whatsappIcon from '../Assets/whatsapp1.webp'; // Adjust the path if necessary
import './WhatsAppButton.css';

const WhatsAppButton = () => {
  const whatsappNumber = '+918764576256'; // Replace with your WhatsApp number
  const message = 'Hello! I would like to inquire about your products.';

  const handleClick = () => {
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-button-container">
      <button
        onClick={handleClick}
        className="whatsapp-button"
        aria-label="Contact us on WhatsApp"
      >
        <img
          src={whatsappIcon}
          alt="WhatsApp"
          className="whatsapp-icon"
        />
      </button>
      <span className="tooltip">Contact us on WhatsApp</span>
    </div>
  );
};

export default WhatsAppButton;
