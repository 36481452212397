import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ShopContextProvider from './Context/ShopContext';

//  Add StrictMode for development (optional)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ShopContextProvider>
        <App />
        </ShopContextProvider>
    </React.StrictMode>
);
