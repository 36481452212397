import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./SearchResults.css";
import Item from "../Item/Item";

const SearchResults = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query"); // Get query from URL params

  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Only fetch data if query exists
    if (query) {
      const fetchResults = async () => {
        setIsLoading(true);
        setError(null);
        try {
          const response = await axios.get(
            `https://jaipurmasale-backend.onrender.com/api/search?query=${query}`
          );
          setResults(response.data.data || []);
        } catch (err) {
          console.error("Error fetching search results:", err);
          setError("Unable to fetch search results. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchResults();
    }
  }, [query]); // Re-fetch when query changes

  return (
    <div>
      <h1>Search Results for "{query}"</h1>
      {isLoading && <p>Loading results...</p>}
      {error && <p className="error-message">{error}</p>}
      {!isLoading && !error && results.length > 0 ? (
        <div className="shopcategory-products">
          {results.map((item) => (
            <Item
              key={item.id}
              id={item.id}
              name={item.product_name}
              image={item.main_image}
              new_price={item.offer_price}
              old_price={item.mrp}
              rating={item.rating}
            />
          ))}
        </div>
      ) : (
        !isLoading && <p>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;
